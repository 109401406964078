
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
//import MixedWidget4 from "@/components/widgets/mixed/Widget7.vue";
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { AnyObject } from "yup/lib/object";

export default defineComponent({
  name: "CZGraph",

  components: {},
  setup() {
    let accountId = store.getters.AccountId;
    const elecSeries = ref([]) as any;
    const eboptions = ref([]) as any;
    const gasSeries = ref([]) as any;
    const gasoptions = ref([]) as any;
    const ebchartType = ref("donut");
    const gaschartType = ref("donut");

    function changeChartType(type) {
      ebchartType.value = type;
    }
    function changeGasChartType(type) {
      gaschartType.value = type;
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs("Asset Register - Supplier Breakdown", [
        "Graphs(s)",
      ]);

      eboptions.value = {
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },

        chart: {
          toolbar: {
            show: true,
            offsetX: -390,
            offsetY: -30,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: "zoom",
          },
          width: 380,
          type: gaschartType,
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          textAnchor: "bottom",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
        },
        fill: {
          type: "gradient",
          colors: "#F3C200",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };

      gasoptions.value = {
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        chart: {
          toolbar: {
            show: true,
            offsetX: -390,
            offsetY: -20,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: "zoom",
          },
          width: 380,
          type: ebchartType,
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          textAnchor: "bottom",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    });
    async function getContent() {
      const accountInfo: any = {
        accountid: accountId,
        DomainName: store.getters.DomainName.toLowerCase(),
      };

      //alert (JSON.stringify(accountInfo))

      ApiService.post(
        "api/AssetRegisterSupplier/assetRegisterSupplier",
        accountInfo
      )
        .then(async ({ data }) => {
          await data;
          // console.log(JSON.stringify(data));
          const response = data;
          if (response["summelec"].length != 0) {
            const electricalSum = response["summelec"][0];
            elecSeries.value.push(electricalSum.total);
            eboptions.value = { labels: [electricalSum.supplier_name] };
          }

          if (response["summgas"].length != 0) {
            const gasSum = response["summgas"][0];
            gasoptions.value = { labels: [gasSum.supplier_name] };
            gasSeries.value.push(gasSum.total);
          }
        })
        .catch();
    }

    getContent();

    return {
      eboptions,
      gasoptions,
      gaschartType,
      ebchartType,
      changeChartType,
      changeGasChartType,
      elecSeries,
      gasSeries,
    };
  },
});
